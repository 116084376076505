import _ from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../../Colors';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { Button, InputElement, Table, } from '../../../../../common';
const etcButtonIcon = {
    show: true,
    name: 'fullArrow',
    width: 19,
    height: 11,
    color: Colors.grey,
};
const EvalTableStyled = styled.div `
	.table {
		width: 100%;
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	&.popup-list-001 {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		height: 100%;

		@keyframes shake {
			0% {
				transform: translateX(0);
			}
			25% {
				transform: translateX(-5px);
			}
			50% {
				transform: translateX(5px);
			}
			75% {
				transform: translateX(-3px);
			}
			100% {
				transform: translateX(3px);
			}
		}

		input.error {
			animation: shake 0.5s ease-in-out;

			background: #fff3f3;
			border-radius: 4px;
			border-style: solid;
			border-color: var(--color-accent, #e7424c);
			border-width: 1px;
			padding: 4px 10px 4px 10px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			color: var(--color-accent, #e7424c);

			&:focus {
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-accent, #e7424c);
				border-width: 2px;
				outline: none;
			}
		}

		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 12px 5px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
		}

		.scroll-content {
			width: 100%;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			flex: 1;
			overflow: visible;
			padding: 0px 6px;

			input {
				padding: 6px 10px 6px 10px;

				text-align: left;
				font-family: 'Noto Sans KR', sans-serif;
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
				position: relative;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.item {
			width: 100%;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: row;
			gap: 6px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
		}
	}
`;
const tabletStyles = css `
	&.popup-list-001 {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		height: 100%;

		@keyframes shake {
			0% {
				transform: translateX(0);
			}
			25% {
				transform: translateX(-5px);
			}
			50% {
				transform: translateX(5px);
			}
			75% {
				transform: translateX(-3px);
			}
			100% {
				transform: translateX(3px);
			}
		}

		input.error {
			animation: shake 0.5s ease-in-out;

			background: #fff3f3;
			border-radius: 4px;
			border-style: solid;
			border-color: var(--color-accent, #e7424c);
			border-width: 1px;
			padding: 4px 10px 4px 10px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			color: var(--color-accent, #e7424c);

			&:focus {
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-accent, #e7424c);
				border-width: 2px;
				outline: none;
			}
		}

		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 12px 20px 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
		}

		.scroll-content {
			width: 100%;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 12px;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
		}

		.item {
			width: 100%;
			padding: 0px 20px 0px 20px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}
	}
`;
const desktopStyles = css `
	&.popup-list-001 {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		height: 100%;

		@keyframes shake {
			0% {
				transform: translateX(0);
			}
			25% {
				transform: translateX(-5px);
			}
			50% {
				transform: translateX(5px);
			}
			75% {
				transform: translateX(-3px);
			}
			100% {
				transform: translateX(3px);
			}
		}

		input.error {
			animation: shake 0.5s ease-in-out;

			background: #fff3f3;
			border-radius: 4px;
			border-style: solid;
			border-color: var(--color-accent, #e7424c);
			border-width: 1px;
			padding: 4px 10px 4px 10px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			color: var(--color-accent, #e7424c);

			&:focus {
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-accent, #e7424c);
				border-width: 2px;
				outline: none;
			}
		}

		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}

		.scroll-content {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			flex: 1;
			overflow: visible;

			input {
				padding: 4px 10px 4px 10px;
			}
		}

		.item {
			width: 100%;
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}
`;
export const EvaluationTable = React.memo(({ subject, isDefault, item, defaultEvalData, setEvalData, setTotalScore, }) => {
    const { isTablet, isMobile } = useGlobalState();
    const [page, setPage] = React.useState(0);
    const [testData, setTestData] = React.useState(defaultEvalData);
    const [totalCount, setTotalCount] = React.useState(0);
    const { headTitleList } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            ...(isDefault
                ? [{ title: 'no', space: 1, sort: false, maxWidth: isMobile ? 27 : 39 }]
                : []),
            { title: 'answer', space: 4, sort: false },
            { title: 'score', space: isTablet ? 1 : 2, sort: false },
            ...(isDefault
                ? []
                : [
                    { title: 'examples', space: 2, sort: false },
                    {
                        title: 'etc',
                        space: 1,
                        sort: false,
                        styles: { justifyContent: 'center' },
                    },
                ]),
        ];
        // useMemo에서 반환
        return { headTitleList };
    }, [isDefault]);
    React.useEffect(() => {
        if (defaultEvalData &&
            defaultEvalData.length > 0 &&
            !_.isEqual(defaultEvalData, testData)) {
            setTestData(defaultEvalData);
            setTotalCount(defaultEvalData.length);
        }
    }, [defaultEvalData]);
    React.useEffect(() => {
        const totalScore = testData.reduce((acc, cur) => {
            return acc + cur.score;
        }, 0);
        setTotalScore({ id: item, total_score: totalScore });
    }, [item, testData]);
    React.useEffect(() => {
        setEvalData((prevData) => {
            const dupDataIndex = prevData.findIndex((data) => data.key === item);
            // 새로운 항목을 추가하는 경우
            if (dupDataIndex === -1) {
                return [...prevData, { data: testData, key: item }];
            }
            // 기존 항목을 업데이트하는 경우
            else {
                const newData = [...prevData];
                newData[dupDataIndex] = { data: testData, key: item };
                return newData;
            }
        });
    }, [testData]);
    const moveItem = (item, direction) => {
        //@ts-ignore
        setTestData(prevData => {
            // const prevData = data;
            const newData = [...prevData];
            const index = prevData.findIndex(data => data.id === item.id);
            // 이동할 수 없는 경우 이전 상태 그대로 반환
            if ((direction === 'up' && index === 0) ||
                (direction === 'down' && index === prevData.length - 1)) {
                return prevData;
            }
            if (direction === 'up') {
                const updatedData = newData.map(data => {
                    if (data.id === item.id) {
                        return { ...data, id: data.id - 1 };
                    }
                    else if (data.id === prevData[index - 1].id) {
                        return { ...data, id: data.id + 1 };
                    }
                    else {
                        return data;
                    }
                });
                // id를 기준으로 정렬한 새로운 배열 반환
                return updatedData.sort((a, b) => a.id - b.id);
            }
            const updatedData = newData.map(data => {
                if (data.id === item.id) {
                    return { ...data, id: data.id + 1 };
                }
                else if (data.id === prevData[index + 1].id) {
                    return { ...data, id: data.id - 1 };
                }
                else {
                    return data;
                }
            });
            return updatedData.sort((a, b) => a.id - b.id);
        });
    };
    // (onChange) 변경된 값 저장
    const onChange = (id, value) => {
        //@ts-ignore
        setTestData((prevData) => {
            const newData = [...prevData];
            const index = prevData.findIndex(data => data.id === id);
            if (index !== -1) {
                newData[index] = {
                    ...newData[index],
                    ...value,
                };
            }
            return newData;
        });
    };
    const renderItem = React.useMemo(() => (item, styles, itemIndex) => {
        return (React.createElement(EvaluationItem, { isDefault: isDefault, item: item, styles: styles, itemIndex: itemIndex, lastIndex: totalCount - 1 === itemIndex, moveItem: moveItem, onChange: onChange, subject: subject, isNumber: subject.includes('math') }));
    }, [isDefault, subject, onChange]);
    return (React.createElement(EvalTableStyled, { "$isTablet": isTablet, "$isMobile": isMobile, id: "eval_table", className: "popup-list-001" },
        React.createElement(Table, { showCheckBox: false, showIndex: isDefault ? false : true, isInifinite: true, data: testData, totalCount: totalCount, page: page, sortDefault: "id", headTitleList: headTitleList, renderItem: renderItem })));
});
const EvaluationItem = React.memo(({ isDefault, item, styles, itemIndex, lastIndex, moveItem, onChange, subject, isNumber, }) => {
    const { isTablet, isMobile } = useGlobalState();
    const examplesRef = React.useRef(null);
    const answerRef = React.useRef(null);
    const scoreRef = React.useRef(null);
    const [firstErrorCheck, setFirstErrorCheck] = React.useState(false);
    const [error, setError] = React.useState({
        answer: false,
        score: false,
        examples: false,
    });
    const { tableReadDataKeys } = React.useMemo(() => {
        const tableReadDataKeys = [
            ...(isDefault ? [{ title: 'no', space: 1, maxWidth: isMobile ? 27 : 39 }] : []),
            { title: 'answer', space: 4 },
            { title: 'score', space: isTablet ? 1 : 2 },
            ...(isDefault
                ? []
                : [
                    { title: 'examples', space: 2 },
                    { title: 'etc', space: 1 },
                ]),
        ];
        // useMemo에서 반환
        return { tableReadDataKeys };
    }, [isDefault]);
    const handlerErrorCheck = () => {
        if (!firstErrorCheck) {
            setFirstErrorCheck(true);
        }
        checkError();
    };
    const checkError = () => {
        let changeError = { ...error };
        const element = answerRef.current;
        const scoreElement = scoreRef.current;
        const examplesElement = examplesRef.current;
        if (element) {
            const value = element.value.trim();
            if (item.type === 'subjective' || item.type === 'short') {
                changeError.answer = value === undefined || value === '' || value.length > 500;
            }
            else {
                const answer = Number(value);
                const examples = Number(examplesElement?.value) || item.examples;
                changeError.answer =
                    value === undefined ||
                        value === '' ||
                        isNaN(answer) ||
                        answer > examples ||
                        answer <= 0;
            }
        }
        if (scoreElement) {
            const value = scoreElement.value.trim();
            const score = Number(scoreElement.value.trim());
            changeError.score =
                value === undefined || value === '' || isNaN(score) || score <= 0;
        }
        if (examplesElement) {
            const value = scoreElement.value.trim();
            const examples = Number(examplesElement.value.trim());
            changeError.examples =
                value === undefined ||
                    value === '' ||
                    isNaN(examples) ||
                    examples <= 0 ||
                    examples > 10;
        }
        setError(changeError);
    };
    React.useEffect(() => {
        APP.eventManager.subscribe('CHECK_ERROR', handlerErrorCheck);
        return () => {
            APP.eventManager.unsubscribe('CHECK_ERROR', handlerErrorCheck);
        };
    }, []);
    React.useEffect(() => {
        setFirstErrorCheck(false);
        setError({
            answer: false,
            score: false,
            examples: false,
        });
    }, [subject]);
    React.useEffect(() => {
        if (scoreRef.current) {
            scoreRef.current.value = item.score.toString() || '2';
        }
        if (firstErrorCheck)
            checkError();
    }, [item.score]);
    React.useEffect(() => {
        // const element = answerRef.current;
        // if (element) {
        // 	const value = element.value;
        // 	if (item.answer !== value) {
        // 		element.value = item.answer;
        // 	}
        // }
        // console.log('element.value', element.value);
        if (firstErrorCheck)
            checkError();
    }, [item.answer]);
    React.useEffect(() => {
        const element = examplesRef.current;
        if (element) {
            const value = element.value;
            if (item.examples && item.examples.toString() !== value) {
                element.value = item.examples.toString();
            }
        }
        if (firstErrorCheck)
            checkError();
    }, [item.examples]);
    const moveItemUp = (item) => moveItem(item, 'up');
    const moveItemDown = (item) => moveItem(item, 'down');
    const numberCheck = isNumber ? isNumber : item.type === 'objective' ? true : false;
    return (React.createElement(React.Fragment, null, tableReadDataKeys.map((headItem, index) => (React.createElement("div", { key: item.id + '-' + headItem.title, id: item.id + headItem.title, style: {
            flex: headItem.space,
            ...styles?.bodyItem,
            ...(headItem.maxWidth && { maxWidth: headItem.maxWidth }),
            overflow: 'hidden',
        } }, headItem.title === 'no' ? (React.createElement("p", null, item.id)) : headItem.title === 'etc' ? (React.createElement("div", { className: "flex column", style: { gap: 4, alignItems: 'center' } },
        React.createElement(Button, { className: `full-arrow-up ${itemIndex === 0 ? 'disable' : 'able'}`, onClick: () => moveItemUp(item), tablIndex: -1, option: {
                buttonBeforeIcon: etcButtonIcon,
            } }),
        React.createElement(Button, { className: `full-arrow-down ${lastIndex ? 'disable' : 'able'}`, onClick: () => moveItemDown(item), tablIndex: -1, option: {
                buttonBeforeIcon: etcButtonIcon,
            } }))) : headItem.title === 'examples' &&
        (item.type === 'subjective' || item.type === 'short') ? ('-') : (React.createElement("div", null,
        React.createElement(InputElement, { className: `${error[headItem.title] ? 'error' : ''}`, ref: headItem.title === 'score'
                ? scoreRef
                : headItem.title === 'answer'
                    ? answerRef
                    : headItem.title === 'examples'
                        ? examplesRef
                        : undefined, defaultValue: headItem.title === 'score'
                ? item.score
                : headItem.title === 'answer'
                    ? item.answer
                    : item.examples, type: headItem.title === 'answer' ? 'text' : 'number', min: headItem.title === 'score' || item.type === 'objective'
                ? 1
                : undefined, style: styles?.bodyText, tabIndex: headItem.title === 'score' ? -1 : 0, isNumber: numberCheck, maxNumLen: isNumber && headItem.title === 'answer'
                ? headItem.title === 'answer'
                    ? item.type === 'objective'
                        ? 5
                        : 5
                    : undefined
                : undefined, onChange: value => {
                let data = undefined;
                try {
                    data =
                        headItem.title === 'score' ||
                            headItem.title === 'examples'
                            ? value * 1
                            : value;
                }
                catch { }
                onChange(item.id, {
                    [headItem.title]: data,
                });
            } }))))))));
});
// React.useEffect(() => {
// 	if (scoreBlank) {
// 		const pervScore = testData.map(item => {
// 			return { id: item.id, score: item.score };
// 		});
// 		setPrevScoreData(pervScore);
// 		// @ts-ignore
// 		setTestData(prevData => {
// 			return prevData.map(item => {
// 				return { ...item, score: 0 };
// 			});
// 		});
// 	} else {
// 		if (prevScoreData && prevScoreData.length > 0) {
// 			//@ts-ignore
// 			setTestData(prevData => {
// 				return prevData.map(item => {
// 					const pervScore = prevScoreData.find(
// 						scoreData => scoreData.id === item.id
// 					);
// 					return { ...item, score: pervScore?.score || 0 };
// 				});
// 			});
// 		}
// 	}
// }, [scoreBlank]);
