import moment from 'moment';
import React, { useEffect } from 'react';
import SurveyItem from './SurveyItem';
import { SURVEY_TYPE } from './constant';
// SurveyLayout 컴포넌트
export const SurveyLayout = React.memo(({ surveyData, setSurveyData, isRegist, isSubmit, }) => {
    const [list, setList] = React.useState(surveyData);
    useEffect(() => {
        setList(surveyData);
    }, [surveyData]);
    useEffect(() => {
        if (isSubmit)
            return;
        setSurveyData && setSurveyData(list);
    }, [list]);
    // 설문 항목 추가 함수
    const addSurveyItem = () => {
        // surveyData 배열의 길이 확인
        const length = list.length;
        // id 생성: -length를 사용하여 고유한 id 생성
        const newItemId = `survey-${moment().unix()}-${length}`;
        // 새로운 설문 항목 객체 생성
        const newItem = {
            id: newItemId,
            title: '',
            type: SURVEY_TYPE.OBJECTIVE,
            data: [],
        };
        // 기존 surveyData 배열에 새로운 항목 추가
        const updatedList = [...list, newItem];
        // 상태 업데이트
        setList(updatedList);
    };
    // 설문 항목 삭제 함수
    const deleteSurveyItem = ({ id }) => {
        if (list && list.length > 1) {
            const newList = list.filter(item => item.id !== id);
            setList(newList);
        }
        else {
            alert('최소 1개 이상의 설문 항목이 필요합니다.');
        }
    };
    // 설문 항목 추가 및 삭제 함수
    const handlerSurveyList = (id) => {
        if (isRegist) {
            if (id) {
                deleteSurveyItem({ id });
            }
            else {
                addSurveyItem();
            }
        }
    };
    // 설문 데이터가 변경되었을 때 처리하는 함수
    const handleSurveyDataChange = (updatedItem) => {
        if (isSubmit)
            return;
        const changeItem = list.find(item => item.id === updatedItem.id);
        if (updatedItem?.title && changeItem?.title === updatedItem?.title)
            return;
        // if (
        // 	!updatedItem?.type &&
        // 	updatedItem?.data &&
        // 	_.isEqual(changeItem?.data, updatedItem?.data)
        // ) {
        // 	return;
        // }
        setList &&
            // @ts-ignore
            setList(prevData => {
                const data = prevData.map(item => {
                    if (item.id === updatedItem.id) {
                        return {
                            ...item,
                            ...updatedItem,
                        };
                    }
                    return item;
                });
                return data;
            });
        // }
    };
    return (React.createElement(React.Fragment, null, list.length > 0
        ? list.map((item, index) => (React.createElement(SurveyItem, { key: index, index: index, item: isSubmit
                ? surveyData?.find(data => data.id === item.id) || item
                : item, isSubmit: isSubmit, isRegist: isRegist, setChangeSurveyList: handlerSurveyList, setChangeSurveyData: handleSurveyDataChange })))
        : null));
});
