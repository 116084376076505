import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
const RegistContentStyled = styled.div `
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1;

	.fext-field {
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		border-radius: 4px;
	}

	.fext-field,
	.answer-content {
		width: 100%;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	${({ $isAnswer, $isRequestion, $isMobile, $isTablet }) => ($isAnswer || $isRequestion) &&
    `
		.ql-editor {
			height: 100%;
			padding: ${$isMobile ? `0;` : $isTablet ? `14px;` : `18px;`}
		}
	`};
`;
export const RegistContent = React.memo(({ isDisbaled = false, hideTitle = false, minHeight, defaultValue, setValue, contentName, handlerTextLength, isAnswer = false, isRequestion = false, isTeacher = false, containerRef, isLearningSupport = false, isPopup = false, }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const quillRef = useRef(null); // Ref to hold the Quill editor instance
    const isInitialized = useRef(false);
    const [editorHtml, setEditorHtml] = useState(defaultValue);
    const [textLength, setTextLength] = useState(0);
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ font: [] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    // ['blockquote', 'code-block'],
                    ['blockquote'],
                    ['image'],
                ],
                handlers: {
                    'letter-spacing': function (value) {
                        if (value) {
                            this.quill.format('letter-spacing', value);
                        }
                    },
                },
            },
        };
    }, []);
    useEffect(() => {
        quillRef.current?.editor.root.setAttribute('spellcheck', 'false');
    }, []);
    useEffect(() => {
        if (!quillRef.current)
            return;
        const editorRoot = quillRef.current.getEditor().root;
        const observer = new MutationObserver(mutations => {
            // console.log('Mutations observed:', mutations);
        });
        observer.observe(editorRoot, { childList: true, subtree: true });
        return () => observer.disconnect();
    }, []);
    useEffect(() => {
        if (!defaultValue)
            return;
        if (!quillRef.current)
            return;
        const quill = quillRef.current.getEditor();
        if (defaultValue) {
            quill.root.innerHTML = defaultValue;
            isInitialized.current = true; // 초기화 플래그 설정
        }
    }, [defaultValue]);
    useEffect(() => {
        handlerTextLength && handlerTextLength(textLength);
    }, [textLength]);
    const handleEditorChange = value => {
        setValue(value); // 상태 업데이트
        setEditorHtml(value);
    };
    const handleKeyDown = (event) => {
        if (!quillRef.current)
            return;
        const editor = quillRef.current.getEditor();
        const currentSelection = editor.getSelection(); // 현재 커서 위치
        if (!currentSelection)
            return;
        // // 입력 키를 에디터에 추가
        // if (event.key.length === 1 && !event.ctrlKey && !event.metaKey) {
        // 	editor.insertText(currentSelection.index, event.key);
        // 	// 커서를 입력 후 위치로 이동
        // 	editor.setSelection(currentSelection.index + 1);
        // 	event.preventDefault();
        // }
        // // Enter 키 처리 예시
        // if (event.key === 'Enter') {
        // 	editor.insertText(currentSelection.index, '\n'); // 줄바꿈 추가
        // 	editor.setSelection(currentSelection.index + 1);
        // 	event.preventDefault();
        // }
    };
    if (isDisbaled) {
        return (React.createElement(RegistContentStyled, { "$isTablet": isTablet, "$isMobile": isMobile, "$isAnswer": isAnswer, "$isRequestion": isRequestion, "$isTeacher": isTeacher, "$isLearningSupport": isLearningSupport, className: "field-quill quill_wrap" },
            !hideTitle && React.createElement("p", { className: "task-label" }, contentName),
            React.createElement("div", { className: "fext-field", style: { minHeight: minHeight ? minHeight : isTablet && '265px' } },
                React.createElement("div", { className: "disable-text ql-editor", style: {
                        minHeight: isAnswer ? '100%' : minHeight ? minHeight : '265px',
                    }, dangerouslySetInnerHTML: { __html: defaultValue } }))));
    }
    useEffect(() => {
        const editorRoot = quillRef.current?.getEditor()?.root;
        if (!editorRoot)
            return;
        const observer = new MutationObserver(() => {
            const content = quillRef.current?.getEditor()?.root.innerHTML;
            if (content !== editorHtml) {
                setEditorHtml(content || '');
                setTextLength(quillRef.current?.getEditor()?.getLength() || 0);
            }
        });
        observer.observe(editorRoot, { childList: true, subtree: true });
        return () => observer.disconnect();
    }, [editorHtml]);
    useEffect(() => {
        const editor = quillRef.current?.getEditor(); // Editor가 있는지 먼저 체크
        const container = containerRef.current;
        if (editor && container) {
            const handlePaste = () => {
                setTimeout(() => {
                    const selection = editor.getSelection();
                    if (selection) {
                        const bounds = editor.getBounds(selection.index);
                        container.scrollTop = bounds.bottom;
                    }
                }, 0);
            };
            editor.on('text-change', handlePaste);
            return () => {
                // Cleanup 시 editor가 존재하는지 확인
                editor.off('text-change', handlePaste);
            };
        }
    }, [containerRef]);
    return (React.createElement(RegistContentStyled, { "$isTablet": isTablet, "$isMobile": isMobile, "$isAnswer": isAnswer, "$isRequestion": isRequestion, "$isTeacher": isTeacher, "$isLearningSupport": isLearningSupport, className: "field-quill quill_wrap" },
        !hideTitle && !isMobile && React.createElement("p", { className: "task-label" }, contentName),
        React.createElement("div", { className: "fext-field content", style: {
                height: '100%',
                justifyContent: 'center',
                minHeight: minHeight ? minHeight : 'undefined',
            } },
            React.createElement(ReactQuill, { className: "answer-content", ref: quillRef, theme: "snow", modules: modules, defaultValue: editorHtml, onKeyDown: handleKeyDown, onChange: handleEditorChange, placeholder: t('supportRoom.contentPlaceHolder') }))));
});
