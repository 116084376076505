import i18next from 'i18next';
import { MiddlewareRegistry } from '../../redux/regist';
import { APP_WILL_LOAD, APP_WILL_UNMOUNT } from '../base/app/actionTypes';
import { DeletePopUpLayout } from '../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../base/popup';
import { goToPrevMenu, updateMenu } from './action';
import { GO_TO_PREV_MENU, SET_MENU } from './actionType';
import { getDepths, getMenuData } from './function';
import _ from 'lodash';
import { DEFAULT_ADMIM_DEPTHS, DEFAULT_ADMIM_MENU, DEFAULT_DEPTHS, DEFAULT_MENU, } from './constants';
let activeRef = false;
const menuConditions = {
    qna: ['answer_modify', 'answer', 'regist', 're_regist'],
    resource: ['regist', 'modify'],
    notice: ['regist', 'modify'],
};
const isMenuConditionMatched = (menu, detail) => {
    return menuConditions[menu]?.some(condition => detail === condition || detail.includes(condition));
};
let stateList = []; // 상태 리스트 관리
// 미들웨어 등록
MiddlewareRegistry.register(store => next => (action) => {
    const { dispatch } = store;
    switch (action.type) {
        case APP_WILL_LOAD: {
            APP.eventManager.subscribe('MENU_MOVE_ACTIVE', isActive => {
                activeRef = isActive;
            });
            // 새로고침 시 상태 복원
            const savedMenus = localStorage.getItem('test_menu');
            if (savedMenus) {
                const prevMenus = JSON.parse(savedMenus);
                if (Array.isArray(prevMenus)) {
                    stateList = prevMenus;
                    const currentMenu = stateList[stateList.length - 1] || null;
                    // 현재 메뉴 복원
                    if (currentMenu) {
                        dispatch(updateMenu(currentMenu.depths, currentMenu.menu, currentMenu.id, currentMenu.props, true));
                    }
                }
            }
            // popstate 이벤트 리스너 등록
            window.addEventListener('popstate', event => {
                if (event.state) {
                    if (event.state.depths && event.state.menu) {
                        dispatch(updateMenu(event.state.depths, event.state.menu, event.state.id, event.state.props, true));
                        if (stateList.length > 1) {
                            stateList.pop(); // 현재 메뉴 제거
                        }
                    }
                    else {
                        const isAdminPage = location.pathname.includes('admin');
                        dispatch(updateMenu(isAdminPage ? DEFAULT_ADMIM_DEPTHS : DEFAULT_DEPTHS, isAdminPage ? DEFAULT_ADMIM_MENU : DEFAULT_MENU));
                        stateList = [];
                    }
                }
            });
            break;
        }
        case APP_WILL_UNMOUNT: {
            stateList = [];
            break;
        }
        case SET_MENU: {
            const newMenu = {
                depths: action.depths || getDepths(store.getState()),
                menu: action.menu,
                id: action.id,
                props: action.props,
                timestamp: Date.now(), // 타임스탬프 추가
            };
            const prevMenu = getMenuData(store.getState());
            if (_.isEqual(newMenu.depths, prevMenu.depths) &&
                _.isEqual(newMenu.menu, prevMenu.menu) &&
                _.isEqual(newMenu.props, prevMenu.props)) {
                return next(action);
            }
            if (prevMenu && prevMenu.menu && !activeRef) {
                if (isMenuConditionMatched(prevMenu.menu.menu, prevMenu.menu.detail)) {
                    APP.eventManager.publish(TOGGLE_POPUP, {
                        component: DeletePopUpLayout,
                        componentProps: {
                            isQnA: true,
                            deleteContent: i18next.t(`supportRoom.answerPopUpcancel`),
                            previosPage: () => {
                                activeRef = true;
                                dispatch(goToPrevMenu());
                                dispatch(updateMenu(newMenu.depths, newMenu.menu, newMenu.id, newMenu.props));
                                APP.eventManager.publish(TOGGLE_POPUP, null);
                                activeRef = false;
                            },
                        },
                        width: 639,
                        title: i18next.t(`common.menuMove`),
                        isFit: true,
                    });
                    return;
                }
            }
            // 히스토리 길이 제한
            if (stateList.length >= 50) {
                stateList.shift(); // 가장 오래된 메뉴 제거
            }
            const state = {
                depths: newMenu.depths,
                menu: newMenu.menu,
                id: newMenu.id,
                props: newMenu.props,
            };
            if (!action.isHistory
            // !(newMenu.menu.detail.includes('modify') || newMenu.menu.detail.includes('regist'))
            ) {
                // 히스토리 상태 추가
                window.history.pushState(state, '', action.url || '');
                // 상태를 stateList에 저장
                stateList.push(state);
            }
            localStorage.setItem('test_menu', JSON.stringify(stateList)); // 로컬 스토리지 동기화
            return next({ ...action, ...newMenu });
        }
        case GO_TO_PREV_MENU: {
            if (stateList.length <= 1) {
                stateList = [];
                break;
            }
            stateList.pop(); // 현재 페이지 제거
            const currentMenu = getMenuData(store.getState());
            // 히스토리에서 가장 최근의 고유한 메뉴 찾기
            let prevMenu = null;
            for (let i = stateList.length - 1; i >= 0; i--) {
                if (!_.isEqual(currentMenu.menu, stateList[i].menu)) {
                    prevMenu = stateList[i];
                    // 현재 인덱스까지의 히스토리만 유지
                    stateList = stateList.slice(0, i + 1);
                    break;
                }
            }
            if (prevMenu) {
                try {
                    localStorage.setItem('test_menu', JSON.stringify(stateList));
                    dispatch(updateMenu(prevMenu.depths, prevMenu.menu, prevMenu.id, prevMenu.props));
                }
                catch (error) {
                    console.error('Failed to save menu history:', error);
                }
            }
            break;
        }
    }
    return next(action);
});
